<template>



  <template v-if="week">
    <WeekViewFazaGerm v-if="week.faza == -1" :week="week" :diary="diary" @updateweek="emits('updateweek')" @removeweek="emits('removeweek', week.id)"/>      
    <WeekViewFazaVeg v-if="week.faza == 0" :week="week" :diary="diary" @updateweek="emits('updateweek')" @removeweek="emits('removeweek', week.id)"/>      
    <WeekViewFazaFlo v-if="week.faza == 1" :week="week" :diary="diary" @updateweek="emits('updateweek')" @removeweek="emits('removeweek', week.id)"/>      
    <WeekViewFazaHar v-if="week.faza == 2" :week="week" :diary="diary" @updateweek="emits('updateweek')" @removeweek="emits('removeweek', week.id)"/>   
  </template> 
  <template v-if="!week && diary">
    <WeekViewFazaEmpty :owner="diary.item_user.id" @choosecreate="emits('choosecreateweek')"/>      
  </template> 


</template>



<script setup>

const emits = defineEmits(['canceleditweek', 'updateweek', 'routedweek', 'choosecreateweek', 'removeweek']);
const route = useRoute();
const props = defineProps({
  week: {
    type: Object    
  },
  diary: {
    type: Object,            
    required: true,
  }
});

// watch(props, (new_props, old_props) => {
//   console.log('new_props');
//   console.log(new_props);
// }, {deep: true})

</script>

 

<style scoped>

 

</style>
